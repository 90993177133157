import {  Form, Input, Modal, Alert, message, Checkbox, Select } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { handleError } from "../../utils/general.util";
import UserAllocation from "../../users/allocation/UserAllocation";
import LoadingSpinner from '../../LoadingSpinner';
import {v4 as uuid} from "uuid";
import { updateEntity } from "../../utils/context-util";

function TaskEditor(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState(props.item);
  const [loading, setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();

  useEffect(() => {
    let user = localStorage.getItem("user");
    if(user){
      user = JSON.parse(user);
    }
    axios.defaults.headers.common['Authorization'] = context?.accessToken ? context.accessToken : user?.accessToken?.jwtToken ;  
    if(item?.id !== props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const onClose = async () => {
    if (typeof props?.onClose == "function") {
      await props?.onClose();
    }
    if(form?.resetFields){
      form.resetFields();
    }
    setLoading(false);
  };

  const validate = (values = form.getFieldsValue()) =>   {     
    let pass = true;
    pass = values.label != null &&
    values.number != null ;
    return pass;
  }

  const createItem = async (event) => {
    try{
      await axios.post(`${context.backendURL}/task`,{...event});
      message.success("Task Created!");
      await updateEntity("task", "tasks", context, props.setOutletContext);
      onClose();
    } 
    catch(err){
      handleError("Task",err,"created",setErrorMessage);
    }
}

const updateItem = async (event) => {
  try{
    await axios.post(`${context.backendURL}/task`,{...event});
    message.success("Task Updated!");
    await updateEntity("task", "tasks", context, props.setOutletContext);
    onClose();
  } 
  catch(err){
    handleError("Task",err,"updated",setErrorMessage);
  }
}

  const onSubmit = async (event) => {
    let newItem = {...event, ...item};
  
    if(!validate(newItem)){            
        setErrorMessage("Please fill out all required fields");
        setLoading(false);
        return;
    }
    if(!newItem?.id){    
      newItem.id = uuid(); 
      newItem.createDate = new Date().toISOString();
      await createItem(newItem);
    }
    else{
      await updateItem(item);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          onClose()
        }
      }}
      onOk={() => {    
          form.validateFields().then(async (values) => {         
            setLoading(true);      
            await onSubmit(values,form);
          });
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} Task</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
  {loading ? <LoadingSpinner/> : <Form
        form={form}
        name="basic"
        onFinish={onSubmit}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
      
      <Form.Item
          label="Task"
          name="label"
          rules={[
            {
              required: true,
              message: "Task is required",
            },
          ]}
        >
          <Input
          disabled={item?.isCompleted} 
          placeholder="What needs to be done" onChange={(event)=>{
            if(item){
              setItem({...item,label:event.currentTarget.value});
            }
          }}/>
        </Form.Item> 
      <Form.Item
          label="Numbers"
          name="number"
          rules={[
            {
              required: true,
              message: "Number is required",
            },
          ]}
        >
          <Input 
          disabled={item?.isCompleted} 
          placeholder="Number..." onChange={(event)=>{
            if(item){
              setItem({...item,number:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"          
        >
        <UserAllocation disabled={item?.isCompleted} model={item?.owner} change={(newValue)=>{
              setItem({...item,owner:newValue});
          }}/>
        </Form.Item>      
        
      {item?.id && <Form.Item
          label="Completed"
          name="isCompleted"    
        >
          <Checkbox disabled={item?.isCompleted} checked={item?.isCompleted} onChange={(event)=>{
            if(item){
              setItem({...item,isCompleted:event.target.checked});
            }
            else{
              setItem({isCompleted:event.target.checked});
            }
        }}/>
        </Form.Item>}
        <Form.Item label="Policies" name="policies">
          <Select
              mode="multiple"
              placeholder="Please select internal controls to link"
              value={item?.policies}
              onChange={(value)=>{
                if(item){
                  setItem({...item,policies:value});
                }
              }}
              filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}            
              filterSort={(optionA, optionB) =>
                (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
              }
            >
              {context?.policies?.filter(r => !r.template).map((control) => {
                return (
                  <Select.Option value={control.id}>
                      {control.label}{control.template ? " - Template" : ""}
                  </Select.Option>
                );
              })}
            </Select>
        </Form.Item>

        <Form.Item label="Risks" name="risks">
          <Select
              mode="multiple"
              placeholder="Please select risks to link"
              value={item?.risks}
              onChange={(value)=>{
                if(item){
                  setItem({...item,risks:value});
                }
              }}
              filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
              }
            >
              {context?.risks?.filter(r => !r.template).map((risk) => {
                return (
                  <Select.Option value={risk.id}>
                      {risk.label}{risk.template ? " - Template" : ""}
                  </Select.Option>
                );
              })}
            </Select>
        </Form.Item>

        <Form.Item label="Internal Controls" name="internalControls">
          <Select
              mode="multiple"
              placeholder="Please select internal controls to link"
              value={item?.internalControls}
              onChange={(value)=>{
                if(item){
                  setItem({...item,internalControls:value});
                }
              }}
              filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
              }
            >
              {context?.internalControls?.filter(r => !r.template).map((ic) => {
                return (
                  <Select.Option value={ic.id}>
                      {ic.label}{ic.template ? " - Template" : ""}
                  </Select.Option>
                );
              })}
            </Select>
        </Form.Item>

        <Form.Item label="Working Papers" name="workingPapers">
          <Select
              mode="multiple"
              placeholder="Please select internal controls to link"
              value={item?.workingPapers}
              onChange={(value)=>{
                if(item){
                  setItem({...item,workingPapers:value});
                }
              }}
              filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
              }
            >
              {context?.workingPapers?.filter(r => !r.template).map((wp) => {
                return (
                  <Select.Option value={wp.id}>
                      {wp.label ? wp.label : wp.id}{wp.template ? " - Template" : ""}
                  </Select.Option>
                );
              })}
            </Select>
        </Form.Item>
      </Form>}
    </Modal>
  );
}

export default TaskEditor;
