import axios from 'axios';

export const updateEntity = async (entityType, key, context, setOutletContext) => {
    let response = await axios.get(`${context.backendURL}/${entityType}`);
    let currentIsClient = context?.user?.roles.indexOf('client') >= 0;
    let currentIsBusiness = context?.user?.roles.indexOf('business') >= 0;

    let entities = response.data.Items;    

    if(currentIsClient){
      entities = entities.filter(f => f.owner?.id == context?.user.id || 
      f.approvers?.filter(a => a.id == context?.user.id)?.length > 0 || 
      f.auditors?.filter(a => a.id == context?.user.id)?.length > 0 || 
      f.reviewers?.filter(a => a.id == context?.user.id)?.length > 0 || 
      f.testers?.filter(a => a.id == context?.user.id)?.length > 0);
    }
    if(currentIsBusiness){
      let clients = context?.clients.filter(c => c.business == context?.user.id);
      entities = entities.filter(f => clients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == context?.user.id);
    }

    let newOutletContext = {...context};
    newOutletContext[key] = entities;
    context[key] = entities;
    if(setOutletContext){
        setOutletContext(newOutletContext);
    }
    return newOutletContext;
}