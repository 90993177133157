import { useEffect, useState } from 'react';
import { Upload, message, Button} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import { useOutletContext } from "react-router-dom";

const S3_BUCKET_URL = 'https://file-staging-uploads.s3.eu-west-1.amazonaws.com/';

function FileUpload(props) {
  const [loading, setLoading] = useState(false);
  const context = useOutletContext();


  let getPresignedUrl = async (filename, content_type) => {  
    let response = await axios.post(`${context.backendURL}/file/upload`,{filename, content_type}); 
    let url = response.data.url;
    return url;
  }

  const handleUpload = async (file) => {
    try {
      setLoading(true);
      const filePath = `${file.uid}/${file.name}`;
      const uploadUrl = await getPresignedUrl(filePath, file.type); 

      let currentToken = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];
      const response = await axios.put(uploadUrl, file, { headers: {
          'Content-Type': file.type
        }}
      );
      axios.defaults.headers.common["Authorization"] = currentToken;

      if (response.status != 200) {
        throw new Error('Error uploading file to S3');
      }

      const uploadedFileUrl = S3_BUCKET_URL + filePath;
      message.success('File uploaded successfully');
      if(props.model.fileList != null){
        props.model.fileList = [
          ...props.model.fileList,
          {
            uid: file.uid,
            name: file.name,
            status: 'done',
            url: uploadedFileUrl,
          },
        ];
      }
      else{
        props.model.fileList = [
          {
            uid: file.uid,
            name: file.name,
            status: 'done',
            url: uploadedFileUrl,
          },
        ];
      }
      
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      message.error('Error uploading file to S3');
      props.model.fileList = props.model.fileList?.filter((f) => f.uid !== file.uid);
    } finally {      
      setLoading(false);
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      handleUpload(file);
      return false;
    },
    fileList: props.model?.fileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  return (
    <Upload {...uploadProps}>
      <Button disabled={props.disabled} loading={loading}>
        <UploadOutlined /> Click to Upload
      </Button>
    </Upload>
  );
}

export default FileUpload;