import React, { useState } from "react";
import { Button, Form, Input, Layout, Typography, Alert } from "antd";
import { Link } from "react-router-dom";
import "./RequestDemo.css"; 
import axios from 'axios';
import { getBackendUrl } from "../../../backend-util";

const { Title } = Typography;
const { Content } = Layout;

const RequestDemo = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onFinish = async (values) => {
    setLoading(true);
    setError(false);
    values['subject'] ='Demo Request on LeafGRC';
    values['message'] = `${values['company']}, ${values['phone']}, ${values['email']}, ${values['domain']}`;
    
    // Ensure email and domain match
    if(values['domain'].includes(values['email'].split('@')[1])){
      try {
        await axios.post(`${getBackendUrl()}/zap`, values);
        setSent(true);
        form.resetFields();
      } catch (err) {
        setError(true);
        setErrorMessage('Error occurred while processing your request.');
      }
    } else {
      setError(true);
      setErrorMessage('Email domain does not match entered domain.');
    }
    
    setLoading(false);
  };

  return (
    <Layout className="layout">
      <Content className="content">
        <div className="home-logo"></div>
        <Title level={2}>Request a Demo</Title>
        {sent ? (
          <div className="sent-message">
            <Title level={4}>Thank you for requesting a demo!</Title>
            <p>We will get back to you as soon as possible to schedule a demo.</p>
            <Link to="/">
              <Button loading={loading} className="contact-button">
                  Back
              </Button>
            </Link>
          </div>
        ) : (
          <Form form={form} onFinish={onFinish} layout="vertical" className="contact-form">
            <Alert
              message="Warning"
              description="A ZAP scan will be initiated on the domain entered."
              type="warning"
              showIcon
              style={{ marginBottom: '15px' }}
            />
            {error && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                showIcon
                style={{ marginBottom: '15px' }}
              />
            )}
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter your name" }]}>
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item name="company" label="Company" rules={[{ required: true, message: "Please enter your company name" }]}>
              <Input placeholder="Enter your company name" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Please enter your email" }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: "Please enter your phone number" }]}>
              <Input placeholder="Enter your phone number" />
            </Form.Item>
            <Form.Item name="domain" label="Domain" rules={[{ required: true, message: "Please enter the domain to scan" }]}>
              <Input placeholder="Enter the domain to scan" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} className="contact-button">
                Submit
              </Button>
              <Link to="/">
                <Button loading={loading} className="contact-button">
                    Back
                </Button>
              </Link>
            </Form.Item>
          </Form>
        )}
      </Content>
    </Layout>
  );
};

export default RequestDemo;
