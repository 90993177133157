import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import UserAllocation from "../users/allocation/UserAllocation";
import { Form, Input, Select, Upload, Alert, Button, DatePicker } from "antd";
import { updateEntity } from "../utils/context-util";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from '../LoadingSpinner';
import FileUpload from "../upload/FileUpload";
import moment from "moment";

const { TextArea } = Input;

function WorkingPaperDisplay(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [fileList, setFileList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [isTester, setIsTester] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);

  const statusses = ["Draft", "Reviewed", "Final", "Issue", "Up for review"];
  const testingStatusses = ["Not Started", "Started", "Issue" , "Exception noted", "No exception noted"];
  const frequencies = ["Yearly", "Quaterly", "Weekly", "Daily"];
  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
    setIsTester(props.item?.testers ? props.item.testers.map(i => i.id).includes(context.user.id) : false);
    setIsReviewer(props.item?.reviewers ? props.item.reviewers.map(i => i.id).includes(context.user.id) : false);
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const loadItems = async () => {
    try{
        loadingItems = true;
        setLoading(true); 
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
      setLoading(false);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
   <div style={{width: 450, maxHeight: 450, overflow: 'auto'}}>
      {errorMessage && <Alert
      message="Error"
      description={errorMessage}
      type="error"
      showIcon
    />}  
      {!editing ? (
        <Button onClick={() => {
          setEditing(true); 
          props.setEditing(true);
        }}>Edit</Button>
      ) : (
        <Button disabled={loading} onClick={async () => {
          setLoading(true);
          try{
            await axios.post(`${context.backendURL}/working-paper`,{...item});
            await updateEntity("working-paper", "workingPapers", context, props.setOutletContext);
            setEditing(false);
            props.setEditing(false);
          }
          finally{
            setLoading(false);
          }
        }}>Save</Button>
      )}
      {loading ? <LoadingSpinner/> : 
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Name" name="label" required={true}>
          <Input
            placeholder={"Please enter a name..."}
            disabled={!editing}
            onChange={(event) => {
              if (item) {            
                setItem({ ...item, label: event.target.value });
              }
            }}
          />
        </Form.Item>        
        <Form.Item
          label="Start Date"
          rules={[
            {
              required: true,
              message: "Start Date is required",
            },
          ]}
        >
          <DatePicker
          value={item?.startDate ? moment(item.startDate): null}
          disabled={!editing}
            onChange={(event) => {
              if (item) {            
                setItem({ ...item, startDate: event });
                form.setFieldValue("startDate",event.toISOString());
              }
            }}/>
        </Form.Item>
        <Form.Item
          label="End  Date"
          rules={[
            {
              required: true,
              message: "End  Date is required",
            },
          ]}
        >
          <DatePicker
          value={item?.endDate ? moment(item.endDate): null}
          disabled={!editing}
            onChange={(event) => {
              if (item) {
                setItem({ ...item, endDate: event });
                form.setFieldValue("endDate", event.toISOString());
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserAllocation 
            disabled={!editing}
            model={item?.owner}
            change={(newValue)=>{
            if(item){
              setItem({...item,owner:{id:newValue.id}});
              form.setFieldValue("owner",{id:newValue.id});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserAllocation 
            disabled={!editing} 
            model={item}
            config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item label="Input" name="input">
          <TextArea 
            disabled={!editing} 
            placeholder="Please enter input here..." />
        </Form.Item>
        <Form.Item label="Analysis" name="analysis">
          <TextArea 
            disabled={!editing}
            placeholder="Please enter analysis..." />
        </Form.Item>
        <Form.Item label="Output" name="output">
          <TextArea 
            disabled={!editing}
            placeholder="Please enter output here..." />
        </Form.Item>
        <Form.Item label="Evidence" name="evidence">
          <FileUpload 
            disabled={!editing}
            model={item} onChange={async (value) => {
                setItem(value);
              }}/>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select 
            mode="multiple" 
            placeholder="Please select status..." 
            disabled={!isTester || !editing}>
            <Select.Option key="Started">Started</Select.Option>
            <Select.Option key="Not Started">Not Started</Select.Option>
            <Select.Option key="Issue">Issue</Select.Option>
            <Select.Option key="Exception Noted">Exception Noted</Select.Option>
            <Select.Option key="No Exception Noted">
              No Exception Noted
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Review Status" name="reviewStatus">
          <Select 
            mode="multiple" 
            placeholder="Please select review status..."
            disabled={!isReviewer || !editing}>
            <Select.Option key="Started">Started</Select.Option>
            <Select.Option key="Not Started">Not Started</Select.Option>
            <Select.Option key="Issue">Issue</Select.Option>
            <Select.Option key="Exception Noted">Exception Noted</Select.Option>
            <Select.Option key="No Exception Noted">
              No Exception Noted
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>}
    </div>

  );
}

export default WorkingPaperDisplay;
