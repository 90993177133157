import { AutoComplete, Button, Col, Input, Alert, Menu, Row, Collapse } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import UserDisplay from "../users/display/UserDisplay";
import FrameworkSelection from "./FrameworkSelection";
import _ from "lodash";

//STYLES
import "./ClientSelection.css";

export default function ClientSelection(props) {
  let frameworks = props.context?.frameworks;
  let user = props.context?.user;
  let users = props.context?.users;
  let clients = props.context?.clients;
  let currentIsAdmin = user?.roles.indexOf('admin') >= 0;
  let currentIsClient = user?.roles.indexOf('client') >= 0;
  let currentIsBusiness = user?.roles.indexOf('business') >= 0;
  const { Panel } = Collapse;

  if(currentIsClient){
    frameworks = frameworks?.filter(f => f.owner?.id == user.id || 
      f.approvers?.filter(a => a.id == user.id)?.length > 0 || 
      f.auditors?.filter(a => a.id == user.id)?.length > 0 || 
      f.reviewers?.filter(a => a.id == user.id)?.length > 0 || 
      f.testers?.filter(a => a.id == user.id)?.length > 0)
    frameworks = frameworks?.filter(f => f.template == false)
  }
  if(currentIsBusiness){
    let businessClients = props.context.clients.filter(c => c.business == user.id);
    frameworks = frameworks.filter(f => businessClients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == user.id);
    clients = businessClients;
  }
  frameworks = frameworks?.filter(f => f != null).filter(f => f.template == false)

  let framework_clients = frameworks?.map(f => clients.find(c => c.userAccount?.email == f.owner?.id)).filter(u => u != undefined);
  framework_clients = _.uniqBy(framework_clients, 'id');

  return <>
    <div className="action-search-buttons">
      {!props.collapsed && (
        <MenuFoldOutlined
          style={{
            marginRight: ".5em",
            marginLeft: ".25em",
            cursor: "pointer",
            color: "white",
          }}
          hidden={!true}
          onClick={() => {
            if (typeof props?.setCollapsed == "function")
              props.setCollapsed(true);
          }}
        />
      )}
      <AutoComplete>
        <Input.Search
          size="medium"
          placeholder="input here"
          enterButton
        />
      </AutoComplete>
    </div>
    {!currentIsClient && <Collapse style={{padding:0}} accordion>
    {clients?.map((client, index) => {
    return (
        <Panel style={{background:"var(--tersiary-color)"}} header={<div style={{display:"flex", color:"var(--white-color)"}}><UserDisplay style={{paddingRight:15, paddingLeft:14}} model={client.userAccount} context={props.context}/>{users.find(u => u.id == client.userAccount.id)?.alias}</div>} key={index}>
          <FrameworkSelection isAdmin={props.isAdmin} items={client.framework?.filter(f => f != undefined).map(f => frameworks.find(i => i.id == f)).filter(f => f != undefined)} context={props.context} collapsed={props.collapsed} updateFramework={props.updateFramework} setCollapsed={props.setCollapsed}/>
        </Panel>
      );
    })}
    </Collapse>}
    {currentIsClient && <FrameworkSelection isAdmin={props.isAdmin} items={clients?.find(c => c.userAccount.id == user.id)?.framework?.map(f => frameworks.find(i => i.id == f))?.filter(f => f != undefined) ?? []} context={props.context} collapsed={props.collapsed} updateFramework={props.updateFramework} setCollapsed={props.setCollapsed}/>}
  </> 
}
