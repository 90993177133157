import { RedoOutlined, AppstoreAddOutlined } from "@ant-design/icons";

import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";

import { MdDeleteOutline } from "react-icons/md";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Input,
  Button,
  Tag,
  Popover,
  Modal,
  Typography
} from "antd";

import React, { useEffect, useState, useCallback, useRef } from "react";

import "./PolicyCrud.css";
import PolicyEditor from "./editor/PolicyEditor";
import TaskEditor from "../tasks/editor/TaskEditor";
import InternalControlDisplay from "../internalControls/InternalControlDisplay";
import UserDisplay from "../users/display/UserDisplay";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import {v4 as uuid} from "uuid";
import { handleError } from "../utils/general.util";
import LoadingSpinner from "../LoadingSpinner";
import { updateEntity } from "../utils/context-util";
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HistoryDisplay from '../history/display/HistoryDisplay';
const type = 'DraggableBodyRow';

const { Text } = Typography;

function Policies(props) {
  const [searchVal, setSearchVal] = useState("");
  const [items, setItems] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const context = useOutletContext();
  const [sortChanged, setSortChanged] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [selectedRiskForTask, setSelectedRiskForTask] = useState(null);

  useEffect(() => {
    if (!items) {
      loadItems(context);
    }
  }); 

  const loadItems = async (currentContext = context) => {    
    if(currentContext.backendURL){     
      try{
        let policies = currentContext.policies;

        let currentIsAdmin = currentContext.user.roles.indexOf('admin') >= 0;
        let currentIsClient = currentContext.user.roles.indexOf('client') >= 0;
        let currentIsBusiness = currentContext.user.roles.indexOf('business') >= 0;
        setIsAdmin(currentIsAdmin);
        if(currentIsClient){
          policies = policies?.filter(f => f.owner?.id == currentContext.user.id || 
            f.approvers?.filter(a => a.id == currentContext.user.id)?.length > 0 || 
            f.auditors?.filter(a => a.id == currentContext.user.id)?.length > 0 || 
            f.reviewers?.filter(a => a.id == currentContext.user.id)?.length > 0 || 
            f.testers?.filter(a => a.id == currentContext.user.id)?.length > 0)
          policies = policies?.filter(f => f.template == false)
        }
        if(currentIsBusiness){
          let clients = currentContext.clients.filter(c => c.business == currentContext.user.id);
          policies = policies.filter(f => clients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == currentContext.user.id);
        }
        policies = policies.sort((a, b) => {
          if(a.index == null || b.index == null){
            return 0;
          }
          return a.index - b.index;
        })
        setItems(policies);
      } 
      catch(err){
        handleError("Policies",err,"loaded",setErrorMessage);
      }
    }
  } 

  const adminColumns = [
    
    {
      title: "Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Type",
      dataIndex: "template",
      key: "template",
      render: (item) => {
        if(item){
          return <Tag color="purple">Template</Tag>
        }
        return <Tag color="orange">Instance</Tag>
      },
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item) => {
        return <UserDisplay model={item} />
      },
    },
    {
      title: "Users",
      key: "users",
      render: (item) => (
        <UserDisplay model={item} config={{multiple:true}}/>
      ),
    },
    {
      title: "Short description",
      dataIndex: "shortDescription",
      key: "shortDescription",
    },
    {
      title: "Version",
      dataIndex: "versionNumber",
      key: "versionNumber",
    },
    {
      title: "Internal Controls",
      dataIndex: "internalControls",
      key: "internalControls",
      render: (items) => items?.map((m) => {
        let loadedItem = context?.internalControls?.find(i => i.id == m);
        if(!loadedItem){
          return null;
        }
        let statusColor = loadedItem.template == true ? "purple" : "orange";
        if(loadedItem.testingStatus == "Not Started"){
          statusColor = "";
        }
        if(loadedItem.testingStatus == "Started"){
          statusColor = "blue";
        }
        if(loadedItem.testingStatus == "Issue"){
          statusColor = "yellow";
        }
        if(loadedItem.testingStatus == "Exception noted"){
          statusColor = "red";
        }
        if(loadedItem.testingStatus == "No exception noted"){
          statusColor = "green";
        }
      return <Popover content={<InternalControlDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Internal Control"} trigger="click">
      <Tag style={loadedItem.notApplicable ? {textDecoration: 'line-through'} : null} color={statusColor}>
        {loadedItem.label}
      </Tag>
    </Popover>
      })
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Review Frequency",
      dataIndex: "reviewFrequency",
      key: "reviewFrequency",
    },
    {
      title: "File / Attachment",
      dataIndex: "fileList",
      key: "fileList",
      render: (item) => item?.map((i) => {
      return <Tag><a href={i.url} target="_blank">{i.name}</a></Tag>
    }),
    },    
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];
  

  const columns = [
    
    {
      title: "Name",
      dataIndex: "label",
      key: "label",
    },   
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item) => {
        return <UserDisplay model={item} />
      },
    },
    {
      title: "Users",
      key: "users",
      render: (item) => (
        <UserDisplay model={item} config={{multiple:true}}/>
      ),
    },
    {
      title: "Short description",
      dataIndex: "shortDescription",
      key: "shortDescription",
    },
    {
      title: "Version",
      dataIndex: "versionNumber",
      key: "versionNumber",
    },
    {
      title: "Internal Controls",
      dataIndex: "internalControls",
      key: "internalControls",
      render: (items) => items?.map((m) => {
        let loadedItem = context?.internalControls?.find(i => i.id == m);
        if(!loadedItem){
          return null;
        }
        let statusColor = "red";
        if(loadedItem.testingStatus == "Not Started"){
          statusColor = loadedItem.template == true ? "purple" : "orange";
        }
        if(loadedItem.testingStatus == "Started"){
          statusColor = "blue";
        }
        if(loadedItem.testingStatus == "Issue"){
          statusColor = "yellow";
        }
        if(loadedItem.testingStatus == "Exception noted"){
          statusColor = "red";
        }
        if(loadedItem.testingStatus == "No exception noted"){
          statusColor = "green";
        }
      return <Popover content={<InternalControlDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Internal Control"} trigger="click">
      <Tag style={loadedItem.notApplicable ? {textDecoration: 'line-through'} : null} color={statusColor}>
        {loadedItem.label}
      </Tag>
    </Popover>
    })
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Review Frequency",
      dataIndex: "reviewFrequency",
      key: "reviewFrequency"
    },
    {
      title: "File / Attachment",
      dataIndex: "fileList",
      key: "fileList",
      render: (item) => item?.map((i) => {
      return <Tag><a href={i.url} target="_blank">{i.name}</a></Tag>
    }),
    },    
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];
  
  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          cursor: 'move',
          ...style,
        }}
        {...restProps}
      />
    );
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const saveSort = async () => {    
    setLoading(true);
    try{
      for(let index in items){
        let item = items[index];
        await axios.post(`${context.backendURL}/policy`, item);
      }
      setSortChanged(false);    
    }
    finally{
      let newContext = await updateEntity("policy", "policies", context, props.setOutletContext);
      await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
    } 
  }

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
        const dragRow = items[dragIndex];
        let newData = update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }); 
        
        for(let index in newData){
          let nData = newData[index];
          nData.index = index;
        }
        setItems(newData);
        let newContext = await updateEntity("policy", "policies", context, props.setOutletContext);
        await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
        setSortChanged(true);     
    },
    [items],
  );

  const menu = (item) => {
    return <Menu>
      <Menu.Item onClick={() => { onPolicyModify(item);}}><BsPencilSquare style={{ marginRight: ".25em" }} /> Update</Menu.Item>
      <Menu.Item onClick={() => { onPolicyRemove(item);}}><MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete</Menu.Item>
      {item.template != true && <Menu.Item onClick={() => { onAddTask(item);}}><AppstoreAddOutlined style={{ marginRight: ".25em" }} /> Add Task</Menu.Item>}
    </Menu>;
  };

  const onPolicySearch = (e) => {
    if (e.code?.toLowerCase() === "enter") {
      if (!searchVal) setItems(items);
      else {
        var tempItems = items.filter((obj) => {
          var found = false;
          for (var key in obj) {
            if (
              `${obj[key]}`.toLowerCase()?.indexOf(searchVal?.toLowerCase()) >
              -1
            )
              found = true;
            if (found) break;
          }
          return found;
        });
        setItems(tempItems);
      }
    }
  };

  const onPolicyModify = (e) => {
    if (e) setSelected(e);
    else setSelected({});
    setModalVisible(true);
  };

  const onAddTask = (e) => {
    setSelectedRiskForTask({id:null,policies:[e.id]});
    setTaskModalVisible(true);
  };

  const onTaskClose = async () => {
    setTaskModalVisible(false);
    setSelectedRiskForTask({});
  };

  const onPolicyClose = async (value) => {
    if (value && JSON.stringify(value,null,2) != '{}') {
      let newItems = [...items];
      if (items?.length) {
        var index = newItems?.findIndex((item) => value?.id === item?.id);
        try{
          setLoading(true);
          if(!value.id || value.id == 'NaN' || value.new){
            if(!value.id || value.id == 'NaN' ){
              value.id =  uuid();
            }
            if(!value.owner){
              value.owner = {
                id:context?.user.id
              }
            }
            if(!value.hasOwnProperty("template")){
              value.template = isAdmin;
            }
          }
          await axios.post(`${context.backendURL}/policy`,value);
          if (index > -1) {
            const item = newItems[index];
            newItems.splice(index, 1, { ...item, ...value });
          } else newItems?.push(value);
          setItems(newItems);
        }
        catch(error){
          handleError("Policy",error,"saved",setErrorMessage);          
        } 
        finally{
          let newContext = await updateEntity("policy", "policies", context, props.setOutletContext);
          await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
          await loadItems(newContext);
          setLoading(false);
        }       
      }
    }
    setModalVisible(false);
    setSelected({});
  };

  const onPolicyRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/policy`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("Policy",error,"deleted",setErrorMessage);    
      }
      finally{
        let newContext = await updateEntity("policy", "policies", context, props.setOutletContext);
        await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
        await loadItems(newContext);
      }
    }
  };

  return <>
  <div style={{ height: "3em" }}>
    <div style={{ float: "left", marginTop: ".5em", marginLeft: ".5em" }}>
      <Space align="center">
        <Input
          size="medium"
          placeholder="Search...."
          style={{ width: "25vw" }}
          onChange={(e) => {
            console.log(e);
            setSearchVal(e.target.value);
          }}
          onKeyDown={(e) => onPolicySearch(e)}
        />
      </Space>
    </div>
    <div style={{ float: "right", marginTop: ".15em" }}>
      <Button
        onClick={() => onPolicyModify(null)}
        type="link"
        className="control"
        size="large"
        icon={
          <AiOutlinePlus
            style={{ fontSize: "1.5em" }}
          />
        }
      />
      <Button
        type="link"
        className="control"
        size="large"
        icon={<AiOutlineHistory style={{ fontSize: "1.5em" }} 
        onClick={()=>{
          setShowHistory(true);
        }}/>}
      />
      <Button
        onClick={async () => {
          setLoading(true);
            let newContext = await updateEntity("policy", "policies", context, props.setOutletContext);
            await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
            await loadItems(newContext);
          }}
        type="link"
        className="control"
        size="large"
        icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
      />
    </div>
  </div>
  {errorMessage && <Alert
  message="Error"
  description={errorMessage}
  type="error"
  showIcon
/>}
  {items && !loading?
  
  <DndProvider backend={HTML5Backend}>
    {sortChanged && <Button 
            style={{marginBottom:16, float:"left"}}
            onClick={async ()=>{
              await saveSort();
            }}>Save Sort Order</Button>}
    <Table 
      dataSource={items} 
      components={components}
      onRow={(_, index) => {
        _.index = index;                
        const attr = {
          index,
          moveRow,
        };
        return attr;
      }}
      columns={isAdmin ? adminColumns : columns} />
  </DndProvider>     
  
  : <LoadingSpinner/>}  
  <PolicyEditor
    isVisible={modalVisible}
    isAdmin={isAdmin}
    onClose={onPolicyClose}
    errorMessage={errorMessage}
    setOutletContext={props.setOutletContext}
    item={selected}
  ></PolicyEditor>
  <TaskEditor
    isVisible={taskModalVisible}
    onClose={onTaskClose}
    errorMessage={errorMessage}
    setOutletContext={props.setOutletContext}
    item={selectedRiskForTask}
  ></TaskEditor>
  <Modal 
        title={"Policy History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false);
          }
        }}      
      >
        <HistoryDisplay context={context} group={"policy"} />
      </Modal>
</>
   
  
}

export default Policies;
